import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import useReport from "../../hook/useReport";
import moment from "moment";
import useDistribution from "../../hook/useDistribution";
import { useQuery } from "../../hook/useQuery";

const FormDistribution = (props) => {
  const [listAccess, setListAccess] = useState(["Iron Park", "Sunrise"]);
  const [accessPrivilege, setAccessPrivilege] = useState([]);
  const [gender, setGender] = useState("");
  const localReport = useReport();
  const [loading, setLoading] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [focus, setFocus] = useState("name");
  const [msg, setMsg] = useState("");
  const [count, setCount] = useState(0);
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const query = useQuery();

  const [baseLite, setBaseLite] = useState([
    {
      name: "Mecka",
      keyword: "mecka",
      active: false,
    },
    {
      name: "PinknicTT",
      keyword: "pink",
      active: false,
    },
    {
      name: "Soaka Street",
      keyword: "soaka",
      active: false,
    },
    {
      name: "Soca Brunch",
      keyword: "soca",
      active: false,
    },
  ]);

  const [addOnLite, setAddOnLite] = useState([
    {
      name: "Wednesday Night Mas",
      keyword: "wednesday",
      active: false,
      total: 0,
    },
    {
      name: "Vibes With Voicey",
      keyword: "vibes",
      active: false,
      total: 0,
    },
    {
      name: "Strut",
      keyword: "strut",
      active: false,
      total: 0,
    },
    {
      name: "Josie's Jamishness",
      keyword: "josie",
      active: false,
      total: 0,
    },
    {
      name: "Spirit Mas",
      keyword: "spirit",
      active: false,
      total: 0,
    },
  ]);

  const [basePremium, setBasePremium] = useState([
    {
      name: "Wednesday Night Mas",
      keyword: "wednesday",
      active: false,
    },
    {
      name: "Vibes With Voicey",
      keyword: "vibes",
      active: false,
    },
    {
      name: "Mecka",
      keyword: "mecka",
      active: false,
    },
    {
      name: "Strut",
      keyword: "strut",
      active: false,
    },
    {
      name: "PinknicTT",
      keyword: "pink",
      active: false,
    },
    {
      name: "Soaka Street",
      keyword: "soaka",
      active: false,
    },
    {
      name: "Soca Brunch",
      keyword: "soca",
      active: false,
    },
  ]);

  const [addOnPremium, setAddOnPremium] = useState([
    {
      name: "Josie's Jamishness",
      keyword: "josie",
      active: false,
      total: 0,
    },
    {
      name: "Spirit Mas",
      keyword: "spirit",
      active: false,
      total: 0,
    },
  ]);

  const [other, setOther] = useState([
    {
      name: "Moet",
      keyword: "moet",
      active: false,
    },
    {
      name: "T-shirt",
      keyword: "shirt",
      active: false,
    },
  ]);

  useEffect(() => {
    if (props.user) {
      setValue("purchaser", props.user?.purchaser_name);
      setValue("name", props.user?.attendee_name);
      setValue("email", props.user?.email);
      initBaseEvent();
      initAddOns();
      initOther();
    }
  }, [props.user]);

  const initOther = () => {
    let arr = [...other];
    let _other = props.user?.other?.split(",");

    let i = 0;
    while (i < _other.length) {
      let item = arr.find(
        (x) => _other[i].toLowerCase() === x.name.toLowerCase()
      );
      if (item !== undefined) {
        item.active = true;
      }
      i++;
    }

    setOther(arr);
  };

  const initBaseEvent = () => {
    let arr =
      props.user?.package_name === "PREMIUM" ? [...basePremium] : [...baseLite];

    let access = props.user?.package_access?.split(",");
    let i = 0;
    while (i < access.length) {
      let item = arr.find(
        (x) => access[i].toLowerCase() === x.name.toLowerCase()
      );
      if (item !== undefined) {
        item.active = true;
      }
      i++;
    }

    if (props.user?.package_name === "PREMIUM") {
      setBasePremium(arr);
    } else {
      setBaseLite(arr);
    }
  };

  const initAddOns = () => {
    let arr =
      props.user?.package_name === "PREMIUM"
        ? [...addOnPremium]
        : [...addOnLite];

    let access = props.user?.addon_access?.split(",");
    let i = 0;
    while (i < access.length) {
      let item = arr.find((x) => access[i].toLowerCase().includes(x.keyword));
      if (item !== undefined) {
        item.active = true;
        let count = access.filter((v) =>
          v.toLowerCase().includes(item.keyword)
        );
        item.total = count.length;
      }

      i++;
    }

    if (props.user?.package_name === "PREMIUM") {
      setAddOnPremium(arr);
    } else {
      setAddOnLite(arr);
    }
  };

  const toogleBaseEvent = (name) => {
    let arr = [];
    if (props.user?.package_name === "PREMIUM") {
      arr = [...basePremium];
    } else {
      arr = [...baseLite];
    }

    let item = arr.find((v) => v.name === name);
    item.active = !item.active;

    if (props.user?.package_name === "PREMIUM") {
      setBasePremium(arr);
    } else {
      setBaseLite(arr);
    }
  };

  const toogleAddOns = (name) => {
    let arr = [];
    if (props.user?.package_name === "PREMIUM") {
      arr = [...addOnPremium];
    } else {
      arr = [...addOnLite];
    }

    let item = arr.find((v) => v.name === name);
    item.active = !item.active;

    if (props.user?.package_name === "PREMIUM") {
      setAddOnPremium(arr);
    } else {
      setAddOnLite(arr);
    }
  };

  const getBasePackageName = () => {
    let ret = [];
    if (props.user?.package_name === "PREMIUM") {
      ret = basePremium
        .filter((v) => v.active === true)
        .map((v) => v.name)
        .join(",");
    } else {
      ret = baseLite
        .filter((v) => v.active === true)
        .map((v) => v.name)
        .join(",");
    }

    return ret;
  };

  const getAddOn = () => {
    let ret = [];
    if (props.user?.package_name === "PREMIUM") {
      ret = addOnPremium
        .filter((v) => v.active === true)
        .map((v) => v.name)
        .join(",");
    } else {
      ret = addOnLite
        .filter((v) => v.active === true)
        .map((v) => v.name)
        .join(",");
    }

    return ret;
  };

  const getOther = () => {
    let ret = other
      .filter((v) => v.active === true)
      .map((v) => v.name)
      .join(",");

    return ret;
  };

  const onSubmit = (data) => {
    setMsg("");

    if (loading) return;

    setLoading(true);

    const payload = {
      ticket_type: props.user?.ticket_type,
      purchaser_name: data.purchaser,
      attendee_name: data.name,
      email: data.email,
      serial_number: props.user?.serial_number,
      package_name: props.user?.package_name,
      package_access: getBasePackageName(),
      addon_access: getAddOn(),
      other: getOther(),
      json_ticket: JSON.stringify(props.user?.json_ticket),
      rfid: data.rfid,
    };

    // if (query.get("rfid") !== "no") {
    //   payload.rfid = data.rfid;
    // }

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + "/distribution/add", form_data, {
          headers: {
            token: Cookies.get("token"),
          },
        })

        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            setLoading(false);
            props.onSuccess();
          } else {
            setMsg(response?.data?.message);
          }
        })
        .catch(function (error) {
          setMsg("Something wrong, please try again");
          setLoading(false);
        });
    } catch (error) {
      setMsg("Something wrong, please try again");
      setLoading(false);
    }
  };

  const resetForm = () => {
    setValue("rfid", "");
  };

  const validateRFID = (value) => {
    if (value.length === 10) {
      return true;
    } else {
      return false;
    }
  };

  const checkAccess = (value) => {
    let active = accessPrivilege.find((v) => value === v);

    return active !== undefined ? true : false;
  };

  const toogleAcces = (value) => {
    let _arr = [...accessPrivilege];
    let exist = accessPrivilege.find((v) => v === value);
    if (exist !== undefined) {
      _arr = accessPrivilege.filter((v) => v !== value);
    } else {
      _arr.push(value);
    }
    setAccessPrivilege(_arr);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-5 px-5 py-5 bg-white rounded-md w-full md:w-3/4"
      >
        <div className="text-center font-bold mb-2">
          {/* <div className="text-2xl">{focus}</div> */}
          <div className="text-2xl">DISTRIBUTION</div>
          <div className="text-black font-semibold text-xl">
            Currently Checked In: {props.total}
          </div>
        </div>

        {true ? (
          <div className="bg-green-500 w-full text-center py-5 text-white font-bold rounded">
            ALLOWED TO COLLECT :{" "}
            <span className="underline">
              {props.user?.total_ticket} TICKETS
            </span>
          </div>
        ) : (
          <div className="bg-red-500 w-full text-center py-5 text-white font-bold rounded">
            NOT ALLOWED TO COLLECT
          </div>
        )}

        <div className="grid grid-cols-1 gap-4">
          {/* PURCHASHER */}
          <div className="flex flex-col">
            <div className="flex justify-between mb-1">
              <label className="text-black w-full font-bold">
                Purchaser <span className="text-red-500">*</span>
              </label>
            </div>

            <input
              autoComplete="off"
              role="presentation"
              onFocus={() => setFocus("rfid")}
              className="border-2 px-2 h-[38px] rounded"
              {...register("purchaser", { required: true })}
            />
          </div>

          {/* ATEENDEE */}
          <div className="flex flex-col">
            <div className="flex justify-between mb-1">
              <label className="text-black w-full font-bold">
                Attendee <span className="text-red-500">*</span>
              </label>
            </div>

            <input
              autoComplete="off"
              role="presentation"
              onFocus={() => setFocus("rfid")}
              className="border-2 px-2 h-[38px] rounded"
              {...register("name", { required: true })}
            />
          </div>

          {/* EMAIL */}
          <div className="flex flex-col">
            <div className="flex justify-between mb-1">
              <label className="text-black w-full font-bold">Email <span className="text-red-500">*</span></label>
            </div>
            <input
              type="email"
              autoComplete="off"
              role="presentation"
              onFocus={() => setFocus("rfid")}
              className="border-2 px-2 h-[38px] rounded"
              {...register("email", { required: true })}
            />
          </div>

          <hr />

          <div className="text-left text-base text-black mb-0 uppercase">
            <span className="font-bold ">Serial Number:</span>{" "}
            <span className="font-bold">{props.user?.serial_number || ""}</span>
          </div>

          <div className="text-left text-base text-black mb-0 uppercase">
            <span className="font-bold ">Ticket Type:</span>{" "}
            <span className="font-bold">{props.user?.ticket_type || ""}</span>
          </div>

          <div className="text-left text-base text-black mb-2 uppercase">
            <span className="font-bold ">BASE PACKAGE:</span>{" "}
            <span className="font-bold">{props.user?.package_name || ""}</span>
          </div>

          <div className=" text-black w-full">
            <div className="mb-2 font-bold">BASE EVENTS:</div>
            <div className="flex flex-wrap">
              {props.user?.package_name === "PREMIUM" &&
                basePremium.map((v, key) => {
                  return (
                    <button
                      key={key}
                      type="button"
                      onClick={() => toogleBaseEvent(v.name)}
                      className={`${
                        v.active ? "bg-orange-500" : "bg-gray-400"
                      } mx-1 mb-1 text-white w-max px-4 py-1 rounded-lg font-bold text-center`}
                    >
                      {v.name}
                    </button>
                  );
                })}

              {props.user?.package_name === "LITE" &&
                baseLite.map((v, key) => {
                  return (
                    <button
                      key={key}
                      type="button"
                      onClick={() => toogleBaseEvent(v.name)}
                      className={`${
                        v.active ? "bg-orange-500" : "bg-gray-400"
                      }  mx-1 mb-1 text-white w-max px-4 py-1 rounded-lg font-bold  text-center`}
                    >
                      {v.name}
                    </button>
                  );
                })}
            </div>
          </div>
          <div className="text-black w-full">
            <div className="mb-2 font-bold">ADD ONS:</div>
            <div className="flex flex-wrap">
              {props.user?.package_name === "PREMIUM" &&
                addOnPremium.map((v, key) => {
                  return (
                    <button
                      key={key}
                      type="button"
                      onClick={() => toogleAddOns(v.name)}
                      className={`${
                        v.active ? "bg-orange-500" : "bg-gray-400"
                      } mx-1 mb-1 text-white w-max px-4 py-1 rounded-lg font-bold text-center`}
                    >
                      {v.name} {v.total !== 0 ? `(${v.total}x)` : ""}
                    </button>
                  );
                })}

              {props.user?.package_name === "LITE" &&
                addOnLite.map((v, key) => {
                  return (
                    <button
                      key={key}
                      type="button"
                      onClick={() => toogleAddOns(v.name)}
                      className={`${
                        v.active ? "bg-orange-500" : "bg-gray-400"
                      }  mx-1 mb-1 text-white w-max px-4 py-1 rounded-lg font-bold  text-center`}
                    >
                      {v.name} {v.total !== 0 ? `(${v.total}x)` : ""}
                    </button>
                  );
                })}
            </div>
          </div>

          <div className=" text-black w-full">
            <div className="mb-2 font-bold">OTHER:</div>
            <div className="flex flex-wrap">
              {other.map((v, key) => {
                return (
                  <div
                    key={key}
                    className={`${
                      v.active ? "bg-orange-500" : "bg-gray-400"
                    } mx-1 mb-1 text-white w-max px-4 py-1 rounded-lg font-bold text-center`}
                  >
                    {v.name}
                  </div>
                );
              })}
            </div>
          </div>

          <hr />

          {query.get("rfid") !== "no" && (
            <>
              {" "}
              {/* SCAN RFID */}
              <div className="flex flex-col">
                <div className="flex justify-between mb-1">
                  <label className="text-black w-full font-bold">
                    Scan RFID
                  </label>
                  <button
                    type="button"
                    onClick={() => resetForm()}
                    className="bg-red-500 px-2 py-1 text-white text-xs rounded font-bold"
                  >
                    Clear
                  </button>
                </div>

                <input
                  autoComplete="off"
                  role="presentation"
                  onFocus={() => setFocus("rfid")}
                  className="border-2 px-2 h-[38px] rounded"
                  {...register("rfid", {
                    required: true,
                    validate: validateRFID,
                  })}
                />
              </div>
              {errors.rfid && (
                <span className="text-red-500 text-sm">
                  RFID must consist of 10 characters
                </span>
              )}
            </>
          )}
        </div>

        <div className="text-center font-bold text-red-500">{msg}</div>

        <div className="mx-auto flex justify-center items-center space-x-4">
          <button
            type="button"
            onClick={() => props.ok()}
            className={`mt-10 w-[340px]  px-10 py-2 rounded-lg text-white font-bold bg-red-500  w-full text-center`}
          >
            CANCEL
          </button>

          <button
            type="submit"
            className={`mt-10 w-[340px]  px-10 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}
          >
            {loading ? "Loading..." : "CONFIRM"}
          </button>
        </div>
      </form>
    </>
  );
};

export default FormDistribution;
