import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import CONFIG from "../constanta/config";
import Cookies from "js-cookie";

const useDistribution = () => {
  const [data, setData] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(CONFIG.URL + "/distribution/list", {
        headers: {
          token: Cookies.get("token"),
        },
      });

      if (response?.data?.status === "SUCCESS") {
       setData(response.data);
      }
    } catch (error) {}
  }, []);

  return { fetchData, data };
};

export default useDistribution;
