import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import useReport from "../../hook/useReport";
import moment from "moment";
import useDistribution from "../../hook/useDistribution";

const FormVerification = (props) => {
  const [loading, setLoading] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [focus, setFocus] = useState("name");
  const [msg, setMsg] = useState("");
  const [count, setCount] = useState(0);
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    // let user = dummy.find((v) => v.qr_code == data.qr_code);
    // if (user) {
    //   props.onSuccess(user, data.qr_code);
    // } else {
    //   props.onSuccess(null, "");
    // }
    checkQrCode(data);
  };

  const baseLite = ["Mecka", "PinknicTT", "Soaka Street", "Soca Brunch"];

  const basePremium = [
    "Wednesday Night Mas",
    "Vibes With Voicey",
    "Mecka",
    "Strut",
    "PinknicTT",
    "Soaka Street",
    "Soca Brunch",
  ];

  const getAddOn = (arr) => {
    let arrAddOn = arr
      .filter(
        (v) =>
          v.ticket_group_type === "addexp" || v.ticket_group_type === "jouvert"
      )
      .map((v) => {
        return v.ticket_title;
      });

    if (arrAddOn.length !== 0) {
      return arrAddOn.join(",");
    } else {
      return "";
    }
  };

  const getOther = (arr) => {
    let arrAddOn = arr
      .filter((v) => v.ticket_group_type === "tshirt")
      .map((v) => {
        return "T-shirt";
      });
    if (arrAddOn.length !== 0) {
      return arrAddOn.join(",");
    } else {
      return "";
    }
  };

  const getBaseTicket = (arr, qr) => {
    let item = arr.find(
      (v) => v.ticket_group_type === "ticket" && v.serial === qr
    );

    if (item) {
      return {
        ticket_type: item.type,
        attendee: item.ticket_user_name,
        serial_number_base: item.serial,
        base_package_name: item.ticket_group === "premium" ? "PREMIUM" : "LITE",
        base_package_access:
          item.ticket_group === "premium"
            ? basePremium.join(",")
            : baseLite.join(","),
      };
    } else {
      return {
        ticket_type: "",
        attendee: "",
        serial_number_base: "-",
        base_package_name: "-",
        base_package_access: "-",
      };
    }
  };

  const getBaseTicketByType = (arr, qr) => {
    let item = arr.find((v) => v.type === "complimentary" && v.serial === qr);

    if (item) {
      return {
        ticket_type: item.type,
        attendee: item.ticket_user_name,
        serial_number_base: item.serial,
        base_package_name: item.ticket_title.toLowerCase().includes("premium")
          ? "PREMIUM"
          : "LITE",
        base_package_access: item.ticket_title.toLowerCase().includes("premium")
          ? basePremium.join(",")
          : baseLite.join(","),
      };
    } else {
      return {
        ticket_type: "",
        attendee: "",
        serial_number_base: "-",
        base_package_name: "-",
        base_package_access: "-",
      };
    }
  };

  const setCanAllow = (arr, qr) => {
    let item = arr.find(
      (v) => v.ticket_group_type === "ticket" && v.serial === qr
    );
    if (item !== undefined) {
      return true;
    } else {
      item = arr.find((v) => v.type === "complimentary" && v.serial === qr);

      if (item !== undefined) {
        return true;
      } else {
        return false;
      }
    }
  };

  const getLengthBase = (arr, qr) => {
    let item = arr.filter((v) => v.ticket_group_type === "ticket");

    if (item.length === 0) {
      let _item = arr.find((v) => v.type === "complimentary");

      if (_item !== undefined) {
        return 1;
      } else {
        return item.length;
      }
    } else {
      return item.length;
    }
  };

  const initTicket = (data, qr) => {
    // ...data.data?.ticket,

    let baseTicket = getBaseTicket(data?.data?.ticket, qr);

    if (baseTicket.serial_number_base === "-") {
      baseTicket = getBaseTicketByType(data?.data?.ticket, qr);
    }

    let ticket = {
      ticket_type: baseTicket.ticket_type,
      purchaser_name: data?.data?.user_name,
      attendee_name: baseTicket.attendee,
      email: data?.data?.user_email,
      serial_number: baseTicket.serial_number_base,
      package_name: baseTicket.base_package_name,
      package_access: baseTicket.base_package_access,
      addon_access: getAddOn(data?.data?.ticket),
      other: getOther(data?.data?.ticket),
      total_ticket: getLengthBase(data?.data?.ticket, qr),
      rfid: "",
      json_ticket: JSON.stringify(data?.data),
      can_allow: setCanAllow(data?.data?.ticket, qr),
    };

    setLoading(false);
    props.onSuccess(ticket);
  };

  const checkQrCode = (data) => {
    if (loading) return;

    setLoading(true);

    const payload = {
      tid: data.qr_code,
      format: "json",
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .get(CONFIG.URL + "/distribution/ticket/" + data.qr_code, {
          headers: {
            token: Cookies.get("token"),
          },
        })

        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            if (response.data?.ticket?.header.error_detail === "") {
              setLoading(false);
              if (response.data.data.length !== 0) {
                props.onSuccess(response.data?.data[0]);
              } else {
                initTicket(response.data?.ticket, data.qr_code);
              }
            } else {
              props.onSuccess(null);
            }
          } else {
            props.onSuccess(null);
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setValue("name", "");
    setValue("rfid", "");
    setValue("qr_code", "");
    reset();
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-5 px-5 py-5 bg-white rounded-md w-full md:w-3/4"
      >
        <div className="text-center font-bold mb-10">
          {/* <div className="text-2xl">{focus}</div> */}
          <div className="text-2xl">DISTRIBUTION</div>
          <div className="text-black font-semibold text-xl">
            Currently Checked In: {props.total}
          </div>
        </div>

        <div className="grid grid-cols-1 gap-10">
          {/* SCAN QR CODE */}
          <div className="flex flex-col">
            <div className="flex justify-between mb-1">
              <label className="text-black w-full font-bold">
                Scan Online QR Code
              </label>
              <button
                type="button"
                onClick={() => resetForm()}
                className="bg-red-500 px-2 py-1 text-white text-xs rounded font-bold"
              >
                Clear
              </button>
            </div>

            <input
              autoFocus
              autoComplete="off"
              role="presentation"
              onFocus={() => setFocus("qr_code")}
              className="border-2 px-2 h-[38px] rounded"
              {...register("qr_code", { required: false })}
            />
          </div>
        </div>

        <div className="text-center font-bold text-red-500">{msg}</div>

        <button
          type="submit"
          className={`min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}
        >
          {loading ? "Loading..." : "Submit"}
        </button>
      </form>
    </>
  );
};

export default FormVerification;
