import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import { useQuery } from "../../hook/useQuery";

const FormAddUser = (props) => {
  const query = useQuery();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [tempName, setTempname] = useState("");

  const [user, setUser] = useState({
    ticket_type: "",
    purchaser_name: "",
    attendee_name: "",
    email: "",
    serial_number: "",
    package_name: "LITE",
    package_access: "Mecka,PinknicTT,Soaka Street,Soca Brunch",
    addon_access: "",
    other: "",
    rfid: "",
  });

  const [type, setType] = useState({
    name: "VIP",
    value: "VIP",
  });
  const listType = [
    {
      name: "VIP",
      value: "VIP",
    },

    // {
    //   name: "General",
    //   value: "GENERAL",
    // },
    // {
    //   name: "General & VIP",
    //   value: "GENERAL,VIP",
    // },
  ];

  const [msg, setMsg] = useState(null);
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const getBaseUrl = () => {
    if (window?.location?.origin) {
      var apiUrl = `${CONFIG.URL_ROOT}/checkin?id_gate=${query.get(
        "id_gate"
      )}&gate=${query.get("gate")}`;
      return apiUrl;
    } else {
      return "";
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setMsg(null);
    }, 1500);
  }, [msg]);

  const onSubmit = (data) => {
    if (loading) return;

    setTempname(data.name);
    setLoading(true);
    const payload = {
      purchaser_name: data.purchaser,
      attendee_name: data.name,
      email: data.email || "",
      serial_number: data.serial_number || "",
      ticket_type: "",
      package_name: basePackage,
      package_access: getBasePackageName(),
      addon_access: getAddOn(),
      other: getOther(),
      rfid: data.rfid,
    };

    console.log(payload);

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + "/user/add", form_data, {
          headers: {
            token: Cookies.get("token"),
          },
        })

        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            reset();
            setMsg({
              success: true,
              msg: "Success Add User",
            });

            setTimeout(() => {
              window.location.href = getBaseUrl();
              setType(null);
            }, 1500);
          } else {
            setMsg({
              success: false,
              msg: "Failed Add User",
            });
          }
        })
        .catch(function (error) {
          setLoading(false);
          setMsg({
            success: false,
            msg: "Failed Add User",
          });
        });
    } catch (error) {
      setLoading(false);
      setMsg({
        success: false,
        msg: "Failed Add User",
      });
    }
  };

  const validateRFID = (value) => {
    if (value.length === 10) {
      return true;
    } else {
      return false;
    }
  };

  const resetForm = () => {
    setValue("rfid", "");
  };

  const [basePackage, setBasePackage] = useState("LITE");

  const [baseLite, setBaseLite] = useState([
    {
      name: "Mecka",
      keyword: "mecka",
      active: false,
    },
    {
      name: "PinknicTT",
      keyword: "pink",
      active: false,
    },
    {
      name: "Soaka Street",
      keyword: "soaka",
      active: false,
    },
    {
      name: "Soca Brunch",
      keyword: "soca",
      active: false,
    },
  ]);

  const [addOnLite, setAddOnLite] = useState([
    {
      name: "Wednesday Night Mas",
      keyword: "wednesday",
      active: false,
      total: 0,
    },
    {
      name: "Vibes With Voicey",
      keyword: "vibes",
      active: false,
      total: 0,
    },
    {
      name: "Strut",
      keyword: "strut",
      active: false,
      total: 0,
    },
    {
      name: "Josie's Jamishness",
      keyword: "josie",
      active: false,
      total: 0,
    },
    {
      name: "Spirit Mas",
      keyword: "spirit",
      active: false,
      total: 0,
    },
  ]);

  const [basePremium, setBasePremium] = useState([
    {
      name: "Wednesday Night Mas",
      keyword: "wednesday",
      active: false,
    },
    {
      name: "Vibes With Voicey",
      keyword: "vibes",
      active: false,
    },
    {
      name: "Mecka",
      keyword: "mecka",
      active: false,
    },
    {
      name: "Strut",
      keyword: "strut",
      active: false,
    },
    {
      name: "PinknicTT",
      keyword: "pink",
      active: false,
    },
    {
      name: "Soaka Street",
      keyword: "soaka",
      active: false,
    },
    {
      name: "Soca Brunch",
      keyword: "soca",
      active: false,
    },
  ]);

  const [addOnPremium, setAddOnPremium] = useState([
    {
      name: "Josie's Jamishness",
      keyword: "josie",
      active: false,
      total: 0,
    },
    {
      name: "Spirit Mas",
      keyword: "spirit",
      active: false,
      total: 0,
    },
  ]);

  const [other, setOther] = useState([
    {
      name: "Moet",
      keyword: "moet",
      active: false,
    },
    {
      name: "T-shirt",
      keyword: "shirt",
      active: false,
    },
  ]);

  useEffect(() => {
    if (user) {
      initBaseEvent();
      initAddOns();
      initOther();
    }
  }, [user]);

  useEffect(() => {
    if (basePackage === "LITE") {
      setUser({
        ticket_type: "",
        purchaser_name: "",
        attendee_name: "",
        email: "",
        serial_number: "",
        package_name: "LITE",
        package_access: "Mecka,PinknicTT,Soaka Street,Soca Brunch",
        addon_access: "",
        other: "",
        rfid: "",
      });
    } else {
      setUser({
        ticket_type: "",
        purchaser_name: "",
        attendee_name: "",
        email: "",
        serial_number: "",
        package_name: "PREMIUM",
        package_access:
          "Mecka,PinknicTT,Soaka Street,Soca Brunch,Wednesday Night Mas,Vibes With Voicey,Strut",
        addon_access: "",
        other: "",
        rfid: "",
      });
    }
  }, [basePackage]);

  const initOther = () => {
    let arr = [...other];
    let _other = user?.other?.split(",");

    let i = 0;
    while (i < _other.length) {
      let item = arr.find(
        (x) => _other[i].toLowerCase() === x.name.toLowerCase()
      );
      if (item !== undefined) {
        item.active = true;
      }
      i++;
    }

    setOther(arr);
  };

  const initBaseEvent = () => {
    let arr =
      user?.package_name === "PREMIUM" ? [...basePremium] : [...baseLite];

    let access = user?.package_access?.split(",");
    let i = 0;
    while (i < access.length) {
      let item = arr.find(
        (x) => access[i].toLowerCase() === x.name.toLowerCase()
      );
      if (item !== undefined) {
        item.active = true;
      }
      i++;
    }

    if (user?.package_name === "PREMIUM") {
      setBasePremium(arr);
    } else {
      setBaseLite(arr);
    }
  };

  const initAddOns = () => {
    let arr =
      user?.package_name === "PREMIUM" ? [...addOnPremium] : [...addOnLite];

    let access = user?.addon_access?.split(",");
    let i = 0;
    while (i < access.length) {
      let item = arr.find((x) => access[i].toLowerCase().includes(x.keyword));
      if (item !== undefined) {
        item.active = true;
        let count = access.filter((v) =>
          v.toLowerCase().includes(item.keyword)
        );
        item.total = count.length;
      }

      i++;
    }

    if (user?.package_name === "PREMIUM") {
      setAddOnPremium(arr);
    } else {
      setAddOnLite(arr);
    }
  };

  const toogleBaseEvent = (name) => {
    let arr = [];
    if (user?.package_name === "PREMIUM") {
      arr = [...basePremium];
    } else {
      arr = [...baseLite];
    }

    let item = arr.find((v) => v.name === name);
    item.active = !item.active;

    if (user?.package_name === "PREMIUM") {
      setBasePremium(arr);
    } else {
      setBaseLite(arr);
    }
  };

  const toogleBasePackage = (name) => {
    setBasePackage(name);
  };

  const toogleAddOns = (name) => {
    let arr = [];
    if (user?.package_name === "PREMIUM") {
      arr = [...addOnPremium];
    } else {
      arr = [...addOnLite];
    }

    let item = arr.find((v) => v.name === name);
    item.active = !item.active;

    if (user?.package_name === "PREMIUM") {
      setAddOnPremium(arr);
    } else {
      setAddOnLite(arr);
    }
  };

  const toogleOther = (name) => {
    let arr = [...other];
    let item = arr.find((v) => v.name === name);
    item.active = !item.active;
    setOther(arr);
  };

  const getBasePackageName = () => {
    let ret = [];
    if (user?.package_name === "PREMIUM") {
      ret = basePremium
        .filter((v) => v.active === true)
        .map((v) => v.name)
        .join(",");
    } else {
      ret = baseLite
        .filter((v) => v.active === true)
        .map((v) => v.name)
        .join(",");
    }

    return ret;
  };

  const getAddOn = () => {
    let ret = [];
    if (user?.package_name === "PREMIUM") {
      ret = addOnPremium
        .filter((v) => v.active === true)
        .map((v) => v.name)
        .join(",");
    } else {
      ret = addOnLite
        .filter((v) => v.active === true)
        .map((v) => v.name)
        .join(",");
    }

    return ret;
  };

  const getOther = () => {
    let ret = other
      .filter((v) => v.active === true)
      .map((v) => v.name)
      .join(",");

    return ret;
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-5 px-5 py-5 bg-white rounded-md w-full md:w-1/2"
      >
        <div className="text-center font-bold mb-5">
          <div className="text-2xl">ADD USER</div>
        </div>

        {msg !== null && msg?.success && (
          <>
            {/* <div className="text-center font-bold mb-8">
              <div className="text-2xl mt-5">Hi, {tempName}</div>
            </div> */}

            <div className="bg-green-500 w-full text-center py-5 text-white font-bold rounded">
              ADD USER SUCCESS
            </div>
          </>
        )}

        {msg !== null && !msg?.success && (
          <div className="text-center font-bold text-white bg-red-500 py-2 rounded">
            {msg?.msg}
          </div>
        )}

        {(msg === null || !msg?.success) && (
          <>
            <div className="grid grid-cols-1 gap-4">
              {/* PURCHASHER */}
              <div className="flex flex-col">
                <div className="flex justify-between mb-1">
                  <label className="text-black w-full font-bold">
                    Purchaser <span className="text-red-500">*</span>
                  </label>
                </div>

                <input
                  autoComplete="off"
                  role="presentation"
                  className="border-2 px-2 h-[38px] rounded"
                  {...register("purchaser", { required: true })}
                />
              </div>

              {/* ATEENDEE */}
              <div className="flex flex-col">
                <div className="flex justify-between mb-1">
                  <label className="text-black w-full font-bold">
                    Attendee <span className="text-red-500">*</span>
                  </label>
                </div>

                <input
                  autoComplete="off"
                  role="presentation"
                  className="border-2 px-2 h-[38px] rounded"
                  {...register("name", { required: true })}
                />
              </div>

              {/* EMAIL */}
              <div className="flex flex-col">
                <div className="flex justify-between mb-1">
                  <label className="text-black w-full font-bold">Email</label>
                </div>
                <input
                  type="email"
                  autoComplete="off"
                  role="presentation"
                  className="border-2 px-2 h-[38px] rounded"
                  {...register("email", { required: false })}
                />
              </div>

              <div className="flex flex-col">
                <div className="flex justify-between mb-1">
                  <label className="text-black w-full font-bold">
                    Serial Number
                  </label>
                </div>
                <input
                  autoComplete="off"
                  role="presentation"
                  className="border-2 px-2 h-[38px] rounded"
                  {...register("serial_number", { required: false })}
                />
              </div>

              <hr />

              <div className=" text-black w-full">
                <div className="mb-2 font-bold">BASE PACKAGE:</div>
                <div className="flex flex-wrap">
                  <button
                    type="button"
                    onClick={() => toogleBasePackage("LITE")}
                    className={`${
                      basePackage === "LITE" ? "bg-orange-500" : "bg-gray-400"
                    } mx-1 mb-1 text-white w-max px-4 py-1 rounded-lg font-bold text-center`}
                  >
                    LITE
                  </button>

                  <button
                    type="button"
                    onClick={() => toogleBasePackage("PREMIUM")}
                    className={`${
                      basePackage === "PREMIUM"
                        ? "bg-orange-500"
                        : "bg-gray-400"
                    } mx-1 mb-1 text-white w-max px-4 py-1 rounded-lg font-bold text-center`}
                  >
                    PREMIUM
                  </button>
                </div>
              </div>

              <div className=" text-black w-full">
                <div className="mb-2 font-bold">BASE EVENTS:</div>
                <div className="flex flex-wrap">
                  {user?.package_name === "PREMIUM" &&
                    basePremium.map((v, key) => {
                      return (
                        <button
                          key={key}
                          type="button"
                          onClick={() => toogleBaseEvent(v.name)}
                          className={`${
                            v.active ? "bg-orange-500" : "bg-gray-400"
                          } mx-1 mb-1 text-white w-max px-4 py-1 rounded-lg font-bold text-center`}
                        >
                          {v.name}
                        </button>
                      );
                    })}

                  {user?.package_name === "LITE" &&
                    baseLite.map((v, key) => {
                      return (
                        <button
                          key={key}
                          type="button"
                          onClick={() => toogleBaseEvent(v.name)}
                          className={`${
                            v.active ? "bg-orange-500" : "bg-gray-400"
                          }  mx-1 mb-1 text-white w-max px-4 py-1 rounded-lg font-bold  text-center`}
                        >
                          {v.name}
                        </button>
                      );
                    })}
                </div>
              </div>
              <div className="text-black w-full">
                <div className="mb-2 font-bold">ADD ONS:</div>
                <div className="flex flex-wrap">
                  {user?.package_name === "PREMIUM" &&
                    addOnPremium.map((v, key) => {
                      return (
                        <button
                          key={key}
                          type="button"
                          onClick={() => toogleAddOns(v.name)}
                          className={`${
                            v.active ? "bg-orange-500" : "bg-gray-400"
                          } mx-1 mb-1 text-white w-max px-4 py-1 rounded-lg font-bold text-center`}
                        >
                          {v.name} {v.total !== 0 ? `(${v.total}x)` : ""}
                        </button>
                      );
                    })}

                  {user?.package_name === "LITE" &&
                    addOnLite.map((v, key) => {
                      return (
                        <button
                          key={key}
                          type="button"
                          onClick={() => toogleAddOns(v.name)}
                          className={`${
                            v.active ? "bg-orange-500" : "bg-gray-400"
                          }  mx-1 mb-1 text-white w-max px-4 py-1 rounded-lg font-bold  text-center`}
                        >
                          {v.name} {v.total !== 0 ? `(${v.total}x)` : ""}
                        </button>
                      );
                    })}
                </div>
              </div>

              <div className=" text-black w-full">
                <div className="mb-2 font-bold">OTHER:</div>
                <div className="flex flex-wrap">
                  {other.map((v, key) => {
                    return (
                      <button
                        key={key}
                        type="button"
                        onClick={() => toogleOther(v.name)}
                        className={`${
                          v.active ? "bg-orange-500" : "bg-gray-400"
                        } mx-1 mb-1 text-white w-max px-4 py-1 rounded-lg font-bold text-center`}
                      >
                        {v.name}
                      </button>
                    );
                  })}
                </div>
              </div>

              <hr />

              {query.get("rfid") !== "no" && (
                <>
                  {" "}
                  {/* SCAN RFID */}
                  <div className="flex flex-col">
                    <div className="flex justify-between mb-1">
                      <label className="text-black w-full font-bold">
                        Scan RFID
                      </label>
                      <button
                        type="button"
                        onClick={() => resetForm()}
                        className="bg-red-500 px-2 py-1 text-white text-xs rounded font-bold"
                      >
                        Clear
                      </button>
                    </div>

                    <input
                      autoComplete="off"
                      role="presentation"
                      className="border-2 px-2 h-[38px] rounded"
                      {...register("rfid", {
                        required: true,
                        validate: validateRFID,
                      })}
                    />
                  </div>
                  {errors.rfid && (
                    <span className="text-red-500 text-sm">
                      RFID must consist of 10 characters
                    </span>
                  )}
                </>
              )}
            </div>

            <div className="flex md:flex-row space-x-5 w-full">
              <a
                href={`./checkin`}
                className={` md:min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-red-500  w-full text-center`}
              >
                Cancel
              </a>
              <button
                type="submit"
                className={`md:min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}
              >
                {loading ? "Loading..." : "Submit"}
              </button>
            </div>
          </>
        )}
      </form>
    </>
  );
};

export default FormAddUser;
