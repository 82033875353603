const getBaseUrl = () => {
  if (window?.location?.origin) {
    var base_url = window.location.origin;

    var apiUrl = `${base_url}/limeeasy/limeeasy-api/api`;
    return apiUrl;
  } else {
    return "";
  }
};

const getBaseUrlMedia = () => {
  if (window?.location?.origin) {
    var base_url = window.location.origin;

    var apiUrl = `${base_url}/limeeasy/limeeasy-api/media`;
    return apiUrl;
  } else {
    return "";
  }
};

const getBaseUrlRoot = () => {
  if (window?.location?.origin) {
    var base_url = window.location.origin;
    var apiUrl = `${base_url}/limeeasy/limeeasy-fe`;
    return apiUrl;
  } else {
    return "";
  }
};

// const CONFIG = {
//   URL: getBaseUrl(),
//   URL_MEDIA: getBaseUrlMedia(),
//   URL_ROOT: getBaseUrlRoot(),
// };

const CONFIG = {
  URL: "https://haeven-api.eventqiu.com/api",
  URL_MEDIA: "https://haeven-api.eventqiu.com/media",
  URL_ROOT: "https://haeven.eventqiu.com",
};

// URL: "https://prestige-api.eventqiu.com/api",
// URL: "http://192.168.100.114/prestige/prestige-api/api",
export default CONFIG;
