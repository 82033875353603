import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import moment from "moment";
import useDistribution from "../../hook/useDistribution";

const FormQRResult = (props) => {
  const [loading, setLoading] = useState(false);
  const [userAttend, setUserAttend] = useState(false);
  const [guestAttend, setGuestAttend] = useState(false);

  const onSubmit = () => {
    props.onSuccess();
  };

  const checkCanSubmit = () => {
    if (props.user !== null && props.user?.rfid === "" && props.user?.can_allow)
      return true;
    else return false;
  };

  return (
    <>
      <div className="space-y-5 px-5 py-5 bg-white rounded-md items-center justify-center flex flex-col w-full md:w-1/2 transition-all">
        <div className="text-center font-bold mb-2">
          {/* <div className="text-2xl">{focus}</div> */}
          <div className="text-2xl">DISTRIBUTION</div>
          <div className="text-black font-semibold text-xl">
            Currently Checked In: {props.total}
          </div>
        </div>
        {props.user !== null ? (
          <>
            {props.user?.rfid !== "" ? (
              <div className="bg-red-500 w-full text-center py-5 text-white font-bold rounded">
                ALREADY COLLECTED
              </div>
            ) : (
              <>
                {props.user?.can_allow ? (
                  <div className="bg-green-500 w-full text-center py-5 text-white font-bold rounded">
                    ALLOWED TO COLLECT :{" "}
                    <span className="underline">
                      {props.user?.total_ticket} TICKETS
                    </span>
                  </div>
                ) : (
                  <div className="bg-red-500 w-full text-center py-5 text-white font-bold rounded">
                    NOT ALLOWED TO COLLECT
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <div className="bg-red-500 w-full text-center py-5 text-white font-bold rounded">
            NOT ALLOWED TO COLLECT
          </div>
        )}

        <div
          className={` text-white flex flex-col justify-center items-center  text-black font-bold px-5 py-1 rounded`}
        >
          {props.user !== null ? (
            <>
              <div className="text-center text-base text-black mb-2">
                <span className="font-normal">Purchaser:</span>{" "}
                <span className="capitalize font-bold">
                  {props.user?.purchaser_name || ""}
                </span>
              </div>
              <div className="text-center text-base text-black mb-2">
                <span className="font-normal">Attendee:</span>{" "}
                <span className="capitalize font-bold">
                  {props.user?.attendee_name || ""}
                </span>
              </div>
              <div className="text-center text-base text-black mb-2">
                <span className="font-normal">Email:</span>{" "}
                <span className="capitalize font-bold">
                  {props.user?.email || ""}
                </span>
              </div>
              <div className="text-center text-base text-black mb-2 uppercase">
                <span className="font-normal ">BASE PACKAGE:</span>{" "}
                <span className="font-bold">
                  {props.user?.package_name || ""}
                </span>
              </div>
              <div className="text-center text-base text-black mb-2 uppercase">
                <span className="font-normal ">Serial Number:</span>{" "}
                <span className="font-bold">
                  {props.user?.serial_number || ""}
                </span>
              </div>
              {props.user?.ticket_type !== "" && (
                <div className="text-center text-base text-black mb-2 uppercase">
                  <span className="font-normal ">Ticket Type:</span>{" "}
                  <span className="font-bold">
                    {props.user?.ticket_type || ""}
                  </span>
                </div>
              )}

              {/* ===== */}
              {/* <div className="text-center text-base text-black mb-2 uppercase">
                <span className="font-normal ">EVENT:</span>{" "}
                <span className="font-bold">
                  {props.user?.base_package_access || ""}
                </span>
              </div>
              <div className="text-center text-base text-black mb-2 uppercase">
                <span className="font-normal ">ADD ON:</span>{" "}
                <span className="font-bold">
                  {props.user?.add_ons_access || ""}
                </span>
              </div> */}
              {/* ===== */}

              {!props.user?.can_allow && props.user?.rfid === "" && (
                <div className="uppercase bg-orange-500 w-full text-center py-5 px-5 text-white font-bold rounded">
                  TICKETS ARE NOT BASE TICKET TYPE, PLEASE SCAN BASE TICKETS.
                </div>
              )}
            </>
          ) : (
            <>
              {" "}
              <div className="text-center  font-bold text-black text-4xl mt-4">
                NOT FOUND
              </div>
            </>
          )}
        </div>

        <div className="mx-auto flex justify-center items-center space-x-4">
          <button
            type="button"
            onClick={() => props.ok()}
            className={`mt-10 w-[340px]  px-10 py-2 rounded-lg text-white font-bold bg-red-500  w-full text-center`}
          >
            CANCEL
          </button>

          {checkCanSubmit() && (
            <button
              type="button"
              onClick={() => onSubmit()}
              className={`mt-10 w-[340px]  px-10 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}
            >
              {loading ? "Loading..." : "CONFIRM"}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default FormQRResult;
